import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './DaynamicPageStyle.css'
import { useNavigate } from "react-router-dom";

const HomeTopSliderLarge = (data) => {
  const navigate = useNavigate()
  let arr = []
  for (let i = 0; i < data?.length; i++) {
    arr.push(
      <a class='DaynamicTopSlider rounded-3' href={data?.[i]?.url} target="_blanck" >
        <img class='rounded-3' src={data?.[i]?.limage} style={{ width: '100%', height: '100%', objectFit: 'fill', }} alt="" />
      </a>
    )
  }
  return arr;
}

const HomeTopSliderSmall = (data) => {
  const navigate = useNavigate()

  let arr = []
  for (let i = 0; i < data?.length; i++) {
    arr.push(
      <a class='DaynamicTopSlider rounded-3' href={data?.[i]?.url} target="_blanck">
        <img class='rounded-3' src={data?.[i]?.simage} style={{ width: '100%', height: '100%', objectFit: 'fill', }} alt="" />
      </a>
    )
  }
  return arr;
}

export const TopDaynamicSliderLarg = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 990 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 990, min: 660 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 660, min: 420 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {HomeTopSliderLarge(data)?.map((el) => {
        return (
          <div class="">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
}

export const TopDaynamicSliderSmall = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 990 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 990, min: 660 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 660, min: 420 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {HomeTopSliderSmall(data)?.map((el) => {
        return (
          <div class="">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
}

export const BigSliderCarts = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 620 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 620, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class="col-11 m-auto bg-light p-3 text-start" style={{ borderRadius: '0px 25px 25px 25px' }} >
            <div class='col-11 m-auto my-2'>
              <div class='d-flex align-items-center justify-content-center' style={{ width: '80px', height: '80px', borderRadius: '50%', background: 'rgba(0,0,0,0.2' }}>
                <div style={{ width: '40px', height: '40px' }}>
                  <img src={el?.image} style={{ width: '100%' }} alt={el?.name} />
                </div>
              </div>
              <div>
                <p class='ts-2 fw-bold mt-3' dangerouslySetInnerHTML={{ __html: el?.name }}></p>
                <div>
                  <p class='ts-2' dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
                </div>
                {
                  el?.button > 0 &&
                  <div class=' d-flex gap-3 align-items-center my-2 mt-3  '>
                    {el?.button?.map((el) => {
                      return (
                        <button class={`${el.css} text-nowrap my-2`} >{el?.btnName} <i class="bi bi-arrow-right "></i></button>
                      )
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        )
      })}
    </Carousel>
  );
}

export const ExtraBigSliderCart = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1400, min: 900 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 900, min: 620 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 620, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class="col-lg-10 col-11 m-auto text-start row p-0 m-0 overflow-hidden align-items-center gap-md-0 gap-4" style={{ borderRadius: '0px 25px 25px 25px', border: '3px solid #FD5900' }} >
            <div class='col-md-4 p-0 overflow-hidden' style={{ objectFit: 'cover' }} >
              <img src={el?.image} style={{ width: '100%', minHeight: '300px', objectFit: 'cover' }} alt="" />
            </div>
            <div class='col-md-8'>
              <div class='col-11 m-auto'>
                <div style={{ backgroundImage: `url(${require('../images/Vector.png')})`, backgroundSize: '80px', backgroundRepeat: 'no-repeat' }}>
                  <br />
                  <p class='fw-bold ts-4' style={{ color: '#FD5900' }} dangerouslySetInnerHTML={{ __html: el?.name }}></p>
                  <p class=' ts-2' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                  <p class=' ts-2 mt-2 tjustify' dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </Carousel>
  );
}

export const SmallSliderCart = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 620 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 620, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={false}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class='col-11 m-auto d-flex gap-2 bg-white rounded-3 text-start align-items-center p-2 '>
            <div class='d-flex align-items-center justify-content-center   icon' style={{ background: '#e9eaf1' }}>
              <img src={el?.image} alt="" />
            </div>
            <div class='' style={{ width: 'fit-content' }}>
              <p class='ts-3 fw-semibold' dangerouslySetInnerHTML={{ __html: el?.name }}></p>
            </div>
          </div>
        )
      })}
    </Carousel>
  );
}

export const ImgTextOverLayCartSmall = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1370 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1370, min: 990 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 990, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={8000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class=" col-lg-10 col-sm-11 m-auto ">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
}

export const Achievement = ({ data }) => {
  const navigate = useNavigate()
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 990 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 990, min: 660 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 660, min: 420 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class="">
            <div class='col-lg-5 col-md-8 col-10 m-auto'>
              <img src={el?.image} style={{ width: '100%' }} alt="" onClick={() => navigate(`${el.url}`)} />
              <p class='my-3 fw-bold ts-3' dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
            </div>
          </div>
        )
      })}
    </Carousel>
  );
} 
