import axios from 'axios';
import md5 from 'md5';
import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from './config';

const Magazine = () => {
  const { selectedLanguage } = useLanguage();
  const [magazine, setMagazine] = useState()


  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)

  useEffect(() => {
    axios.post(`${API_BASE_URL}/magazinepage`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        setMagazine(res?.data?.data)
      })
      .catch((err) => {
      })
  }, [selectedLanguage, token])


  // const handleDownload = (url) => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', url.split('/').pop());
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };



  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>{selectedLanguage == 'en' ? 'Magazine' : 'મેગેઝિન'}</title>
        <meta name="description" content={selectedLanguage == 'en' ? 'Magazine' : 'મેગેઝિન'} />
        <meta name="keywords" content="KDCC Bank Magazine" />
      </Helmet>
      <div style={{ background: '#ffefe6' }}>
        <br />
        <div>
          <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}>{selectedLanguage == 'en' ? "Magazine" : "મેગેઝિન"} </p>
        </div>
        <br />
      </div>
      <div class='mt-5 col-11 m-auto' style={{ overflowX: 'auto' }}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" >{selectedLanguage == 'en' ? 'No.' : 'સંખ્યા'}</th>
              <th scope="col" >{selectedLanguage == 'en' ? 'Name' : 'નામ'}</th>
              <th scope="col" > {selectedLanguage == 'en' ? 'Description' : 'વર્ણન'}</th>
              <th scope="col" > {selectedLanguage == 'en' ? 'Documents' : 'દસ્તાવેજો'}</th>
            </tr>
          </thead>
          <tbody>
            {
              magazine?.map((el, i) => {
                return (
                  <tr style={{ borderLeft: '0.5px solid #1A2B71' }}>
                    <td>{i + 1}</td>
                    <td dangerouslySetInnerHTML={{ __html: el.name }}></td>
                    <td dangerouslySetInnerHTML={{ __html: el.desc }}></td>
                    <td><button class='btn-orang' onClick={() => handleDownload(el.doc)} >{selectedLanguage == 'en' ? 'Download' : 'ડાઉનલોડ'} <span> <i class="bi bi-cloud-arrow-down"></i></span></button></td>
                  </tr>
                )
              })
            }

          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Magazine