import axios from "axios";
import md5 from "md5";
import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useLanguage } from "./LanguageContext";
import { API_BASE_URL } from "./config";

const
  ApplyNowStatus = () => {
    const [queryOtpSection, setQueryOtpSection] = useState(true);
    const [querySection, setQuerySection] = useState(false);
    const [refrenceNumber, setRefrenceNumber] = useState("");
    const [otpBtn, setOtpBtn] = useState(false);
    const [otp, setOtp] = useState("");
    const [fillOtp, setFillOtp] = useState("");

    const [query, setQuery] = useState();
    const { selectedLanguage } = useLanguage();

    useEffect(() => {
      setOtp(md5(fillOtp));
    }, [fillOtp]);


    const date = new Date()
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    }

    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    const sendOtp = () => {
      axios
        .post(`${API_BASE_URL}/otp-check-apply-now`, {
          RefrenceNo: refrenceNumber,
        }, { headers: { 'X-Custom-Token': token } })
        .then((response) => {
          if (response.data.success == true) {
            setOtpBtn(true);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `${response?.data?.message}`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${response?.data?.message}`,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Something Wrong`,
          });
        });
    };

    const submitOtp = () => {
      axios
        .post(`${API_BASE_URL}/check-apply-now`, {
          RefrenceNo: refrenceNumber,
          OTP: otp,
        }, { headers: { 'X-Custom-Token': token } })
        .then((response) => {
          if (response.data.success == true) {
            setQueryOtpSection(false);
            setQuerySection(true);
            setQuery(response?.data?.data);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${error?.response?.data?.message?.Phone?.[0]}`,
          });
        })
    };

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{selectedLanguage == "en" ? "Apply Now Status" : "હવે સ્થિતિ લાગુ કરો"}</title>
          <meta name="description" content={selectedLanguage == "en" ? "Apply Now Status" : "હવે સ્થિતિ લાગુ કરો"} />
          <meta name="keywords" content={selectedLanguage == "en" ? "Apply Now Status" : "હવે સ્થિતિ લાગુ કરો"} />

        </Helmet>

        <div style={{ background: '#ffefe6' }}>
          <br />
          <div>
            <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "Apply Now Status" : "હવે સ્થિતિ લાગુ કરો"}</p>
          </div>
          <br />
        </div>

        <div
          class="text-start col-xl-6 col-md-8 col-11 m-auto mt-5 "
        >
          {!queryOtpSection ? (
            ""
          ) : (
            <div>
              <label class="mt-2">{selectedLanguage == "en" ? "Track your Service Request" : "તમારી સેવા વિનંતીને ટ્રૅક કરો"} </label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Refrence Number"
                  onChange={(e) => setRefrenceNumber(e.target.value)}
                />
                <span>
                  <button
                    class="btn-orang"
                    style={{ zIndex: "0" }}
                    // disabled={otpBtn ? true : false}
                    onClick={() => sendOtp()}
                  >
                    {selectedLanguage == "en" ? "Verify" : "ચકાસો"}

                  </button>
                </span>
              </div>
              {!otpBtn ? (
                ""
              ) : (
                <div class="form-group mt-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="OTP"
                    name="PageName"
                    maxLength={6}
                    onChange={(e) => setFillOtp(e.target.value)}
                  />
                </div>
              )}

              <button
                class="btn-orang form-control mt-3"
                // disabled={otpBtn ? false : true}
                onClick={submitOtp}
              >
                {selectedLanguage == "en" ? "Submit" : "સબમિટ કરો"}
              </button>
            </div>
          )}

          {!querySection ? (
            ""
          ) : (
            <div>
              <table class="table  border ">
                <tr>
                  <td>
                    <p class="p-2 m-0 fw-bold  ">{selectedLanguage == "en" ? "Status" : "સ્થિતિ"} :</p>
                  </td>
                  <td>
                    <p class="p-2 m-0  ">{query?.status}</p>
                  </td>
                </tr>
                <tr style={{ background: "#f2f2f2" }}>
                  <td>
                    <p class="p-2 m-0 fw-bold ">{selectedLanguage == "en" ? "Note" : "નૉૅધ"} :</p>
                  </td>
                  <td>
                    <p class="p-2 m-0 ">{query?.note}</p>
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  };

export default ApplyNowStatus;
